

/**
 * Contains the js for allowing the user to add/remove from their various collections
 * Currently a product can only belong to one collection at a time - this is handled in the service layer
 */

define(['app', 'siteObj'], function(app, siteObj) {

  class CustomerCollections {

    constructor() {
      this.bindEvents();
      //code that rebinds the events after the page updates
      let self = this;
      app.subscribe('pageControls/update', function() {
        self.bindEvents();
      });
    }

    errorCallback(response) {
      console.log(response);
    }

    // Bind event handlers
    bindEvents() {
      //create variables for the tabs on the collections tracker page
      const wishlistTabLink = document.querySelector('[data-wishlistsection]');
      const blacklistTabLink = document.querySelector('[data-blacklistsection]');
      const collectionTabLink = document.querySelector('[data-collectionsection]');
      const wishlistTab = document.querySelector('.section_wishlist');
      const blacklistTab = document.querySelector('.section_blacklist');
      const collectionTab = document.querySelector('.section_collection');

      //create variables to bind all the icons for the wishlist functionality need to refresh on paging
      let collectionIconEls = document.querySelectorAll('[data-collectionicon]');
      let wishlistEls = document.querySelectorAll('[data-wishlist]');
      let blacklistEls = document.querySelectorAll('[data-blacklist]');
      let collectionEls = document.querySelectorAll('[data-collection]');
      let categoriesWhiteEls = document.querySelectorAll('[data-category_whitelist]');
      let categoriesBlackEls = document.querySelectorAll('[data-category_blacklist]');


      this.collectionsTabs(wishlistTabLink, 'wishlistsection', wishlistTab);
      this.collectionsTabs(blacklistTabLink, 'blacklistsection', blacklistTab);
      this.collectionsTabs(collectionTabLink, 'collectionsection', collectionTab);


      // find all collection button divs and bind click event
      for (let i = 0; i < collectionIconEls.length; i++) {
        app.element.on('click', e => this.clickHandler(e, 'collectionicon'), collectionIconEls[i]);
      }

      // find all wishlist buttons
      for (let i = 0; i < wishlistEls.length; i++) {
        if (wishlistEls[i].getAttribute('data-wishlist') === 'remove') {
          this.updateIcon(wishlistEls[i], 'wishlist');
        }
        app.element.on('click', e => this.clickHandler(e, 'wishlist'), wishlistEls[i]);
      }

      // find all blacklist buttons
      for (let i = 0; i < blacklistEls.length; i++) {
        if (blacklistEls[i].getAttribute('data-blacklist') === 'remove') {
          this.updateIcon(blacklistEls[i], 'blacklist');
        }
        app.element.on('click', e => this.clickHandler(e, 'blacklist'), blacklistEls[i]);
      }

      // find all collection buttons
      for (let i = 0; i < collectionEls.length; i++) {
        if (collectionEls[i].getAttribute('data-collection') === 'remove') {
          this.updateIcon(collectionEls[i], 'collection');
        }
        app.element.on('click', e => this.clickHandler(e, 'collection'), collectionEls[i]);
      }

      // find all category buttons
      for (let i = 0; i < categoriesWhiteEls.length; i++) {
        app.element.on('click', e => this.clickHandler(e, 'category_whitelist'), categoriesWhiteEls[i]);
        if (categoriesWhiteEls[i].getAttribute('data-category_whitelist') === 'remove') {
          this.updateIcon(categoriesWhiteEls[i], 'wishlist');
        }
      }

      for (let i = 0; i < categoriesBlackEls.length; i++) {
        app.element.on('click', e => this.clickHandler(e, 'category_blacklist'), categoriesBlackEls[i]);
        if (categoriesBlackEls[i].getAttribute('data-category_blacklist') === 'remove') {
          this.updateIcon(categoriesBlackEls[i], 'blacklist');
        }
      }
    }

    clickHandler(event, collection) {
      let target = event.target || event.srcElement;

      let nameOfSectionLinks = {
        blacklistsection: 'blacklistsection',
        collectionsection: 'collectionsection',
        wishlistsection: 'wishlistsection'
      };
      let selectLink = nameOfSectionLinks[collection];
      //make the tabs work for the collection page.

      if (selectLink) {
        this.switchTabsOnClick(collection, nameOfSectionLinks);
        return;
      }

      if (collection === 'collectionicon') {
        //show and hide collections button div on icon click
        if (target.getAttribute('data-collectionicon') === 'show') {
          target.setAttribute('data-collectionicon', 'hide');
          let collectionButtons = app.element.closest('.customerCollections_buttons', target).element;
          app.element.removeClass('customerCollections_buttons-hide', collectionButtons);
          if (collectionButtons.hasChildNodes()) {
            var child = collectionButtons.children[0];
            var self = this;
            app.element.on('blur', function() {
              self.setDataToShow(target);
            }, child);
            child.focus();
          }
          return;

        } else if (target.getAttribute('data-collectionicon') === 'hide') {
          target.setAttribute('data-collectionicon', 'show');
          let collectionButtons = app.element.closest('.customerCollections_buttons', target).element;
          app.element.addClass('customerCollections_buttons-hide', collectionButtons);

        }
        return;
      }

      if (collection === 'category_whitelist') {
        let methodName = 'addToCategoriesILike';

        if (target.getAttribute(`data-${collection}`) === 'remove') {
          methodName = 'removeFromCategoriesILike';
        }

        let categoryElement = app.element.closest('[data-category-name]', target);
        let category = categoryElement.getAttribute('data-category-name');

        this[methodName]([category], '', true);
        return;
      }

      if (collection === 'category_blacklist') {
        let methodName = 'addToCategoriesIDislike';
        if (target.getAttribute(`data-${collection}`) === 'remove') {
          methodName = 'removeFromCategoriesIDislike';
        }

        let categoryElement = app.element.closest('[data-category-name]', target);
        let category = categoryElement.getAttribute('data-category-name');

        this[methodName]([category], '', true);

      } else {
        let methodSuffix = collection.charAt(0).toUpperCase() + collection.slice(1);
        let methodName = `addTo${methodSuffix}`;
        if (target.getAttribute(`data-${collection}`) === 'remove') {
          methodName = `removeFrom${methodSuffix}`;
        }

        // get from siteObj first for product pages
        let product = siteObj.productID;
        if (product === null || product === undefined) {
          let productElement = app.element.closest('.js-enhanced-ecommerce-data', target);
          product = productElement.getAttribute('data-product-id');
        }

        this[methodName]([product]);
      }
    }

    addToCollection(productIds) {
      this.addToWithMetadata(productIds, 'collection', false, () => this.addToCollectionSuccessCallback(productIds), this.errorCallback);
    }

    addToCollectionSuccessCallback(productIds) {
      this.addToSuccessCallback(productIds, 'collection');
    }

    addToWishlist(productIds) {
      this.addToWithMetadata(productIds, 'wishlist', false, () => this.addToWishlistSuccessCallback(productIds), this.errorCallback);
    }

    addToWishlistSuccessCallback(productIds) {
      this.addToSuccessCallback(productIds, 'wishlist');
    }

    addToBlacklist(productIds) {
      this.addToWithMetadata(productIds, 'blacklist', false, () => this.addToBlacklistSuccessCallback(productIds), this.errorCallback);
    }

    addToBlacklistSuccessCallback(productIds) {
      this.addToSuccessCallback(productIds, 'blacklist');
    }

    addToSuccessCallback(productIds, collection) {
      let productDiv = document.querySelectorAll(`[rel="${productIds[0]}"]`)[0];

      this.removeSelectedButton('wishlist', productDiv);
      this.removeSelectedButton('blacklist', productDiv);
      this.removeSelectedButton('collection', productDiv);
      this.addSelectedButton(collection, productDiv);
      this.updateIcon(productDiv, collection);
      this.setDataToShow(productDiv);
      this.closePopup(productDiv);

      app.publish('tracking/record', 'Customer Collection', collection + ' | Added', 'Product | ' + productIds[0]);
    }

    addToCategoriesILike(category) {
      this.addToWithMetadata(category, 'category_whitelist', true, () => this.addToCategoriesSuccessCallback(category, 'category_whitelist', 'wishlist'), this.errorCallback);
    }

    addToCategoriesIDislike(category) {
      this.addToWithMetadata(category, 'category_blacklist', true, () => this.addToCategoriesSuccessCallback(category, 'category_blacklist', 'blacklist'), this.errorCallback);
    }

    addToCategoriesSuccessCallback(category, list, icon) {
      let categoryDiv = document.querySelectorAll(`[rel="${category[0]}"]`)[0];

      this.removeSelectedButton('category_whitelist', categoryDiv);
      this.removeSelectedButton('category_blacklist', categoryDiv);
      this.addSelectedButton(list, categoryDiv);
      this.updateIcon(categoryDiv, icon);
      this.closePopup(categoryDiv);

      app.publish('tracking/record', 'Customer Collection', list + ' | Added', 'Category | ' + category[0]);
    }

    addToWithMetadata(elements, type, mapCategoriesToSkus, successCallback, errorCallback) {
      let endpoint = `/${type}/${mapCategoriesToSkus}/addTo.collections`;

      app.ajax.post({
        url: endpoint,
        send: elements,
        requestHeader: {
          header: 'Content-Type',
          value: 'application/json'
        },
        success: function() {
          successCallback(elements);
        },
        error: function(response) {
          errorCallback(response);
        }
      });
    }


    // DELETE items from customer collections

    /**
     * This is the function that actually does the work
     * @param type
     * @param productIds
     * @param successCallback
     * @param errorCallback
     */
    removeFrom(type, elements, successCallback, errorCallback) {
      let endpoint = `/${type}/removeFrom.collections`;
      app.ajax.call({
        url: endpoint,
        send: elements,
        type: 'DELETE',
        requestHeader: {
          header: 'Content-Type',
          value: 'application/json'
        },
        success: () => successCallback(elements),
        error: (response) => errorCallback(response)
      });
    }

    removeFromCollection(productIds) {
      this.removeFrom('collection', productIds, () => this.removeFromCollectionSuccessCallback(productIds), this.errorCallback);
    }

    removeFromCollectionSuccessCallback(productIds) {
      this.removeFromSuccessCallback(productIds, 'collection');
    }

    removeFromWishlist(productIds) {
      this.removeFrom('wishlist', productIds, () => this.removeFromWishlistSuccessCallback(productIds), this.errorCallback);
    }

    removeFromWishlistSuccessCallback(productIds) {
      this.removeFromSuccessCallback(productIds, 'wishlist');
    }

    removeFromBlacklist(productIds) {
      this.removeFrom('blacklist', productIds, () => this.removeFromBlacklistSuccessCallback(productIds), this.errorCallback);
    }

    removeFromBlacklistSuccessCallback(productIds) {
      this.removeFromSuccessCallback(productIds, 'blacklist');
    }

    removeFromCategoriesILike(category) {
      this.removeFrom('category_whitelist', category, () => this.removeFromCategoriesSuccessCallback(category, 'category_whitelist', 'wishlist'), this.errorCallback);
    }

    removeFromCategoriesIDislike(category) {
      this.removeFrom('category_blacklist', category, () => this.removeFromCategoriesSuccessCallback(category, 'category_blacklist', 'blacklist'), this.errorCallback);
    }

    removeFromCategoriesSuccessCallback(category, list, icon) {
      let categoryDiv = document.querySelectorAll(`[rel="${category[0]}"]`)[0];

      this.removeSelectedButton(list, categoryDiv);
      this.updateDataAttribute(categoryDiv, list, 'add');
      this.resetIcon(categoryDiv, icon);
      this.setDataToShow(categoryDiv);
      this.closePopup(categoryDiv);

      app.publish('tracking/record', 'Customer Collection', list + ' | Removed', 'Category | ' + category[0]);

    }

    removeFromSuccessCallback(productIds, collection) {

      let productDiv = document.querySelectorAll(`[rel="${productIds[0]}"]`)[0];

      this.removeSelectedButton(collection, productDiv);
      this.updateDataAttribute(productDiv, collection, 'add');
      this.resetIcon(productDiv, collection);
      this.setDataToShow(productDiv);
      this.closePopup(productDiv);

      app.publish('tracking/record', 'Customer Collection', collection + ' | Removed', 'Product | ' + productIds[0]);
    }

    //functions that update the Icon on page load to indicate if product is in a list
    updateIcon(item, icon) {


      if (document.querySelector('.subscriptionButton')) {

        this.resetMobileButton('.subBtn-neutral');
        this.resetMobileButton('.subBtn-blacklist');
        this.resetMobileButton('.subBtn-collection');
        this.resetMobileButton('.subBtn-wishlist');

        let visibleButton = document.querySelector(`.subBtn-${icon}`);
        app.element.removeClass('subBtn-hide', visibleButton);

      } else {
        let optionsIcon = app.element.closest('.customerCollections_indicator', item).element;
        app.element.removeClass('neutral-icon', optionsIcon);
        app.element.removeClass('wishlist-icon', optionsIcon);
        app.element.removeClass('blacklist-icon', optionsIcon);
        app.element.removeClass('collection-icon', optionsIcon);
        app.element.addClass(`${icon}-icon`, optionsIcon);
      }

      let optionSelected = app.element.closest('.customerCollections_buttons', item).element;
      app.element.addClass('selected', optionSelected);

    }

    collectionsTabs(element, section, tab) {
      if (element) {
        this.showActiveTab(element, section, tab);
        app.element.on('click', e => this.clickHandler(e, section), element);
      }
    }

    showActiveTab(element, section, tab) {
      if (element.getAttribute(`data-${section}`) === 'show') {
        app.element.addClass('active', tab);
      }
    }

    switchTabsOnClick(collection, namesOfLinks) {
      let collectionList = {
        blacklistsection: {
          name: namesOfLinks.blacklistsection,
          link: document.querySelector(`[data-${namesOfLinks.blacklistsection}]`),
          section: document.querySelector('.section_blacklist')
        },
        collectionsection: {
          name: namesOfLinks.collectionsection,
          link: document.querySelector(`[data-${namesOfLinks.collectionsection}]`),
          section: document.querySelector('.section_collection')
        },
        wishlistsection: {
          name: namesOfLinks.wishlistsection,
          link: document.querySelector(`[data-${namesOfLinks.wishlistsection}]`),
          section: document.querySelector('.section_wishlist')
        }
      };

      let sections = [
        collectionList.blacklistsection.section,
        collectionList.collectionsection.section,
        collectionList.wishlistsection.section
      ];

      let tabLinks = [
        collectionList.blacklistsection,
        collectionList.collectionsection,
        collectionList.wishlistsection
      ];

      this.setTabLinkToInactive(tabLinks);
      this.removeActiveClassFromAllTabs(sections);
      this.showActiveTabOnClick(collectionList[collection]);
    }

    setTabLinkToInactive(tabLinks) {
      for (var i = 0, len = tabLinks.length; i < len; i++) {
        var tabLink = tabLinks[i].link;
        tabLink.setAttribute(`data-${tabLink.name}`, 'hide');
        app.element.removeClass('active', tabLink);
      }
    }

    removeActiveClassFromAllTabs(sections) {
      for (var i = 0, len = sections.length; i < len; i++) {
        app.element.removeClass('active', sections[i]);
      }
    }

    showActiveTabOnClick(item) {
      if (item) {
        item.link.setAttribute(`data-${item.name}`, 'show');
        app.element.addClass('active', item.link);
        app.element.addClass('active', item.section);
      }
    }

    removeSelectedButton(list, item) {
      let button = app.element.closest(`[data-${list}]`, item).element;
      app.element.removeClass(`${list}-remove`, button);
      app.element.addClass(`${list}-add`, button);
      button.setAttribute(`data-${list}`, 'add');
    }

    addSelectedButton(collection, item) {
      let button = app.element.closest(`[data-${collection}]`, item).element;
      app.element.removeClass(`${collection}-add`, button);
      app.element.addClass(`${collection}-remove`, button);
      button.setAttribute(`data-${collection}`, 'remove');
    }

    closePopup(item) {
      let optionSelected = app.element.closest('.customerCollections_buttons', item).element;
      app.element.addClass('customerCollections_buttons-hide', optionSelected);
    }

    setDataToShow(item) {
      //catch for mobile buttons that have different markup
      if (document.querySelector('.subscriptionButton')) {
        let subBtn = app.element.closest('.subscriptionButton', item).element;
        subBtn.setAttribute('data-collectionicon', 'show');
      } else {
        let optionsIcon = app.element.closest('.customerCollections_indicator', item).element;
        optionsIcon.setAttribute('data-collectionicon', 'show');
      }
    }

    updateDataAttribute(item, collection, value) {
      let button = app.element.closest(`[data-${collection}]`, item).element;
      button.setAttribute(`data-${collection}`, value);
    }

    resetIcon(item, icon) {
      if (document.querySelector('.subscriptionButton')) {

        this.resetMobileButton('.subBtn-blacklist');
        this.resetMobileButton('.subBtn-collection');
        this.resetMobileButton('.subBtn-wishlist');

        let subBtnNeutral = document.querySelector('.subBtn-neutral');
        app.element.removeClass('subBtn-hide', subBtnNeutral);
        subBtnNeutral.setAttribute('data-collectionicon', 'show');

      } else {

        let optionsIcon = app.element.closest('.customerCollections_indicator', item).element;
        app.element.removeClass(`${icon}-icon`, optionsIcon);
        app.element.addClass('neutral-icon', optionsIcon);
        optionsIcon.setAttribute('data-collectionicon', 'show');
      }

      let optionSelected = app.element.closest('.customerCollections_buttons', item).element;
      app.element.removeClass('selected', optionSelected);
    }

    resetMobileButton(className) {
      let button = document.querySelector(className);
      app.element.addClass('subBtn-hide', button);
      button.setAttribute('data-collectionicon', 'show');
    }
  }

  return new CustomerCollections();
});
